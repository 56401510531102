import React, { useEffect } from 'react';
import './index.scss';

const ScrollTestPage = () => {

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 1000)

  });

  return (
    <div>
      <div className='sticky-test'>
        <div className='sticky-dl'>
          <div>
            <div className='sticky-dt'>A</div>
            <div className='sticky-dd'>Andrew W.K.</div>
            <div className='sticky-dd'>Apparat</div>
            <div className='sticky-dd'>Arcade Fire</div>
            <div className='sticky-dd'>At The Drive-In</div>
            <div className='sticky-dd'>Aziz Ansari</div>
          </div>
          <div>
            <div className='sticky-dt'>C</div>
            <div className='sticky-dd'>Chromeo</div>
            <div className='sticky-dd'>Common</div>
            <div className='sticky-dd'>Converge</div>
            <div className='sticky-dd'>Crystal Castles</div>
            <div className='sticky-dd'>Cursive</div>
          </div>
          <div>
            <div className='sticky-dt'>E</div>
            <div className='sticky-dd'>Explosions In The Sky</div>
          </div>
          <div>
            <div className='sticky-dt'>T</div>
            <div className='sticky-dd'>Ted Leo &amp; The Pharmacists</div>
            <div className='sticky-dd'>T-Pain</div>
            <div className='sticky-dd'>Thrice</div>
            <div className='sticky-dd'>TV On The Radio</div>
            <div className='sticky-dd'>Two Gallants</div>
          </div>
          <div>
            <div className='sticky-dt'>T</div>
            <div className='sticky-dd'>Ted Leo &amp; The Pharmacists</div>
            <div className='sticky-dd'>T-Pain</div>
            <div className='sticky-dd'>Thrice</div>
            <div className='sticky-dd'>TV On The Radio</div>
            <div className='sticky-dd'>Two Gallants</div>
          </div>
          <div>
            <div className='sticky-dt'>T</div>
            <div className='sticky-dd'>Ted Leo &amp; The Pharmacists</div>
            <div className='sticky-dd'>T-Pain</div>
            <div className='sticky-dd'>Thrice</div>
            <div className='sticky-dd'>TV On The Radio</div>
            <div className='sticky-dd'>Two Gallants</div>
          </div>
          <div>
            <div className='sticky-dt'>T</div>
            <div className='sticky-dd'>Ted Leo &amp; The Pharmacists</div>
            <div className='sticky-dd'>T-Pain</div>
            <div className='sticky-dd'>Thrice</div>
            <div className='sticky-dd'>TV On The Radio</div>
            <div className='sticky-dd'>Two Gallants</div>
          </div>
          <div>
            <div className='sticky-dt'>T</div>
            <div className='sticky-dd'>Ted Leo &amp; The Pharmacists</div>
            <div className='sticky-dd'>T-Pain</div>
            <div className='sticky-dd'>Thrice</div>
            <div className='sticky-dd'>TV On The Radio</div>
            <div className='sticky-dd'>Two Gallants</div>
          </div>
        </div>
      </div>

      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test1*/}
      {/*</div>*/}
      {/*<div style={{ position: 'sticky', top: 0}}>sticky header</div>*/}
      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test2*/}
      {/*</div>*/}
      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test3*/}
      {/*</div>*/}
      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test4*/}
      {/*</div>*/}
      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test5*/}
      {/*</div>*/}
      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test6*/}
      {/*</div>*/}
      {/*<div style={{ height: 200, backgroundColor: 'aqua', margin: 20 }}>*/}
      {/*  Test7*/}
      {/*</div>*/}
    </div>

  );
};

export default ScrollTestPage;
